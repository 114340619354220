import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ComponentBase } from '../../shared/component-base';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import { DlgContractService } from '../dlg-contract.service';
import { DlgLockerModalComponent } from '../dlg-locker-modal';
import { DlgTransferModalComponent } from '../dlg-transfer-modal';
import { AlertService } from '../shared-dlg.module';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { UsersServiceProxy } from '../../service-proxies/service-proxies';
import { Router } from '@angular/router';
import { DlgUnlockWalletComponent } from '../dlg-unlock-wallet';
import { BigNumber } from 'bignumber.js';
import { DlgLockerInfoComponent } from '../dlg-locker-info';
import { DlgTierInfoComponent } from '../dlg-tier-info';
import { KYC_required } from 'src/internal/kyc.decorator';
import {TranslateService} from "@ngx-translate/core";
import swal from "sweetalert2";
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';

@Component({
  selector: 'main-page',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})

export class MainPageComponent extends ComponentBase implements OnInit, OnDestroy {
  constructor(
    private _dialog: MatDialog,
    private _dlgContractSrv: DlgContractService,
    private _alertSrv: AlertService,
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private usersService: UsersServiceProxy,
    private router: Router,
    public translate: TranslateService
  ) {
    super();
  }

  waiting: boolean = false;
  account: string = '';

  updateUserTimerId: NodeJS.Timeout;

  userWalletBalance: string;
  totalUserBlpBalance: string;
  lockerOldUserBalance: string;
  lockerUserBalance: string;
  lockedWithBonusValue: string;
  stakingUserBalance: string;
  farmingUserBalance: string;
  bonusBalance: string;
  bonusInterval: number = 0;

  lockedTotalBalance: string;
  penaltyAmount: string;
  userTierIndex: number = -1;
  nextTierIndex: number;
  maxTierValue: number;
  isMaxTier = false;
  currentTierProgress:number = 0;
  nextTierValue: string;
  nextTierNeedValue: any;

  allTiers: any[] = new Array();
  allPenalties: any[] = new Array();
  userLockingStartTimestamp: number;
  allTiersLength: number = 0;

  now = Math.floor(Date.now() / 1000);
  // Last participation time
  lastParticipationTime: number = 0;
  lockupDuration: number = 0;

  lockedBalancePercent: number = 0;
  stakingPercent: number = 0;
  chartData: ChartData<'doughnut'>;
  chartOptions = {
    offset: 0,
    aspectRatio: 1,
    responsive: true,
    cutoutPercentage: 92,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    animation: {
      animateRotate: false,
    }
  };

  public doughnutChartData: ChartData<'doughnut'>;
  public doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
    offset: 0,
    aspectRatio: 1,
    cutout:73.5,
    responsive: true,
    animation: {
      animateRotate: false,
    }
  };

  isAuthorized: boolean = false;

  get canTransfer(): boolean {
    return Number(this.lockerUserBalance) > 0;
  }

  async ngOnInit() {
    this.isAuthorized = this.userSessionProvider.isAuthorized;
    await this.web3Service.initWeb3();
    if (this.web3Service.web3) {
      this.updateContractData();
    }

    if (this.userSessionProvider.linkedWallet) {
      this.eventLogin(this.userSessionProvider.linkedWallet);
    }
    this.eventBus.loginEvent.subscribe(result => {
      // console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      // console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });

    this.eventBus.logoutEvent.subscribe(result => {
      // console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });

    this.eventBus.needUpdateUsersInfo.subscribe(result => {
      // console.log('needUpdateUsersInfo subscription:' + result);
      this.updateUserData();
      this.updateTokensGraph();
    });
  }

  async ngOnDestroy() {
    if (this.updateUserTimerId) {
      clearInterval(this.updateUserTimerId);
    }
  }

  async eventLogin(username: string) {
    // console.log('eventLogin');
    // console.log(username);
    if (this.account != username) {
      this.account = username;

      await this.updateUserData();
      await this.updateTokensGraph();

      this.updateUserTimerId = setInterval(() => {
        this.updateUserData();
      }, this.expectedBlockTime);

      // this.getTokensDebts(username);
    }
  }

  eventLogout(): void {
    // console.log('signOut');
    this.account = '';

    // this.tokenDebt = 0;
    // this.totalInvestedETH = 0;
    // this.ethBalance = 0;
    // this.totalStakeETH = null;
    // this.totalBuyToken = null;
    // this.myClaimedTokensAmount = null;
    // this.tokensDebt = new Array();

    if (this.updateUserTimerId) {
      clearInterval(this.updateUserTimerId);
    }
  }

  async updateContractData() {
    let tiesrLenght = parseInt(await this.web3Service.getDealLockupsTiersLength());
    for (let i = 0; i < tiesrLenght; i++) {
      let tier = await this.web3Service.getDealLockupsTiers(i);
      this.allTiers.push(tier);
      this.allTiersLength = tiesrLenght;
    }
    await this.updateUserData();
    await this.updateTokensGraph();

    for (let i = 0; i < 5; i++) {
      let penalties = await this.web3Service.getLockerPenalties(i);
      //console.log(penalties);
      if(penalties != null)
        this.allPenalties.push(penalties);
    }
    this.userLockingStartTimestamp = parseInt(await this.web3Service.getUserLockingStart(this.account));
  }

  public get isLockedBalanceExist(): boolean
  {
    let tkn = parseInt(this.lockerOldUserBalance);
    return tkn > 0;
  }

  private async getPoolBalance(poolAddress: string ): Promise<string>{
    const poolBalanceData = await this.web3Service.getPoolUserInfo(this.account, poolAddress);
    return poolBalanceData?.[0] || 0;
  }

  async updateUserData() {
    if (this.account) {
      this.userWalletBalance = await this.web3Service.GetTokenBalance(this.account, this.web3Service.blpAddress);
      this.lockerUserBalance = await this.web3Service.getLockedTokenAmount(this.web3Service.lockerAddress, this.account);
      this.stakingUserBalance = await this.getPoolBalance(this.web3Service.getStackingAddress);
      this.lockerOldUserBalance = await this.web3Service.getLockedTokenAmount(this.web3Service.oldLockerAddress, this.account);
      this.lockedTotalBalance = await this.web3Service.getLockedTokenAmountTierCalculator(this.web3Service.tierCalculatorAddress, this.account);
      this.bonusBalance = await this.web3Service.getBonusAmount(this.web3Service.bonusAddress, this.account);
      this.bonusInterval = parseInt(await this.web3Service.getBonusInterval()) / 60;

      //this.lockerUserBalance = new BigNumber(this.lockerUserBalance).plus(new BigNumber(this.bonusBalance)).toFixed();

      const penaltiesData = await this.web3Service.getLockerPenaltyBP(this.web3Service.lockerAddress, this.account);
      if(penaltiesData)
        this.penaltyAmount = String(penaltiesData[1]);
      let lockedBalanceBn = new BigNumber(this.lockerUserBalance).plus(new BigNumber(this.stakingUserBalance)).plus(new BigNumber(this.bonusBalance));
      let tierIndex = 0;
      let nextIndex = 0;
      let maxTier = 0;
      let success = false;
      const lastIndex = this.allTiers.length - 1;
      for (let i = 0; i < this.allTiers.length; i++) {
        maxTier = this.allTiers[i].blpAmount;
        if (this.allTiers[i] && lockedBalanceBn.isGreaterThanOrEqualTo(new BigNumber(this.allTiers[i].blpAmount))
          //Check that next level is higher than previous
          && new BigNumber(this.allTiers[i].blpAmount).isGreaterThanOrEqualTo(new BigNumber(this.allTiers[tierIndex].blpAmount))) {
          tierIndex = i;
          nextIndex = i + 1;
          success = true;
          //console.log('[tier] check success');
        }
      }
      if (nextIndex > lastIndex) {
        nextIndex = lastIndex;
      }
      this.maxTierValue = maxTier;
      if (success) {
        this.userTierIndex = tierIndex;
      } else {
        this.userTierIndex = -1;
      }
      this.nextTierIndex = nextIndex;
      // Is current tier is max & filled
      if (this.allTiersLength === tierIndex && Number(this.lockerUserBalance) + Number(this.stakingUserBalance) + Number(this.bonusBalance) >= maxTier) {
        this.isMaxTier = true;
        this.currentTierProgress = 100;
      } else {
        if(!this.allTiers[nextIndex]) return;
        const nextTierValue = this.allTiers[nextIndex].blpAmount;
        const leftToNext = parseInt(nextTierValue) - (parseInt(this.lockerUserBalance) + parseInt(this.stakingUserBalance) + parseInt(this.bonusBalance));
        if (leftToNext > 0 ) {
          this.nextTierNeedValue = leftToNext;
        }
        this.currentTierProgress = (parseInt(this.lockerUserBalance) + parseInt(this.stakingUserBalance) + parseInt(this.bonusBalance)) * 100 / parseInt(nextTierValue);
        this.nextTierValue = nextTierValue;
      }
      this.web3Service.getDealLockupsTiers(this.userTierIndex).then(async (resp) => {
        this.lockupDuration = parseInt(resp[1]);
      });

      this.web3Service.getLastParticipations(this.account).then((resp) => {
        this.lastParticipationTime = parseInt(resp);
      });
    }
  }

  async updateTokensGraph() {
    /*TODO: Get staking & farming*/
    const lockedValue = (Number(this.lockerUserBalance) || 0) + (Number(this.bonusBalance) || 0);
    const stakingValue = Number(this.stakingUserBalance) || 0;
    const totalBLP = lockedValue + stakingValue;

    this.lockedWithBonusValue = lockedValue.toFixed();
    this.totalUserBlpBalance = String(totalBLP);
    this.stakingPercent = Math.round(stakingValue * 100 / totalBLP) || 0;
    this.lockedBalancePercent = Math.round(lockedValue * 100 / totalBLP) || 0;;

    this.chartData = {
      labels: ['Staking', 'Locked-In'],
      datasets: [
        {
          data: [this.stakingPercent, this.lockedBalancePercent],
          backgroundColor: ['#131BCB', '#31C2B9'],
          borderWidth: 0,
        }
      ]
    };
  }

  getTranslatedTier(tierIndex: number) {
    if (tierIndex == -1)
      return this.translate.instant('none');
    if (tierIndex == 0)
      return "Baby Paca";
    if (tierIndex == 1)
      return "Little Paca";
    if (tierIndex == 2)
      return "Chief Paca";
    if (tierIndex == 3)
      return "Elder Paca";
    if (tierIndex == 4)
      return "Alpha Paca";
    return this.translate.instant('none');
  }

  getTranslatedShowPeriod(value: number): string {
    const timerViewDays = Math.floor(value / (3600 * 24));
    const timerViewHours = Math.floor(value % (3600 * 24) / 3600);
    const timerViewMin = Math.floor(value % 3600 / 60);
    const timerViewSec = Math.floor(value % 60);
    let stringData = "";
    if (timerViewDays)
      stringData += `${timerViewDays} ${this.translate.instant('time.day')} `;
    if (timerViewHours)
      stringData += `${timerViewHours} ${this.translate.instant('time.hours')} `;
    if (timerViewMin)
      stringData += `${timerViewMin} ${this.translate.instant('time.min')} `;
    if (timerViewSec)
      stringData += `${timerViewSec} ${this.translate.instant('time.ss')} `;
    return stringData;
  }

  public showWarningInfoAndLockTokenClick(): void {
    swal.fire({
      text: this.translate.instant('thePoolsWhileHaving1YearLock'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yesIAmSure'),
      cancelButtonText: this.translate.instant('noIAmNotSure'),
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.lockTokenClick();
      } else if (result.isDenied) {
        return;
      }
    });
  }

  @KYC_required
  public lockTokenClick(): void {
    if (this.account) {
      this.callLockerComponent(true);
    } else {
      this.unlockWalletClick();
    }
  }

  @KYC_required
  public unlockTokenClick(): void{
    if (this.account) {
      this.callLockerComponent(false);
    } else {
      this.unlockWalletClick();
    }
  }

  @KYC_required
  public transferTokenClick(): void{
    if (this.account) {
      this.callTransferComponent();
    } else {
      this.unlockWalletClick();
    }
  }

  private callLockerComponent(IsLockedMode: boolean): void{
    const dialogRef = this._dialog.open(DlgLockerModalComponent, {
      panelClass: ['dlg-light', 'dlg-small'],
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.IsLockedMode = IsLockedMode;
    dialogRef.afterClosed().subscribe(result => {
      this.updateUserData();
      this.updateTokensGraph();
    });
  }

  private callTransferComponent(): void{
    const dialogRef = this._dialog.open(DlgTransferModalComponent, {
      panelClass: ['dlg-light', 'dlg-small'],
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      this.updateUserData();
      this.updateTokensGraph();
    });
  }


  async unlockWalletClick() {
    const dialogRef = this._dialog.open(DlgUnlockWalletComponent, {
      panelClass: ['dlg-light', 'dlg-small'],
      scrollStrategy: new NoopScrollStrategy(),
    });
  }
  async lockTokenOldClick(IsLockedMode: boolean) {
    if (this.account) {
      const dialogRef = this._dialog.open(DlgLockerModalComponent, {
        panelClass: ['dlg-light', 'dlg-small'],
        scrollStrategy: new NoopScrollStrategy(),
      });
      dialogRef.disableClose = true;
      dialogRef.componentInstance.IsLockedMode = IsLockedMode;
      dialogRef.componentInstance.isOldLocker = true;

      dialogRef.afterClosed().subscribe(result => {
        this.updateUserData();
        this.updateTokensGraph();
      });
    }
    else {
      this.unlockWalletClick();
    }
  }

  async unlockWalletOldClick() {
    const dialogRef = this._dialog.open(DlgUnlockWalletComponent, {
      panelClass: ['dlg-light', 'dlg-small'],
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  async openUnlockInfoDlg() {
    const dialogRef = this._dialog.open(DlgLockerInfoComponent, {
      panelClass: ['dlg-light', 'dlg-small'],
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  async showTierInfoDlg(i: number) {
    const dialogRef = this._dialog.open(DlgTierInfoComponent, {
      panelClass: ['dlg-light', 'dlg-small'],
      scrollStrategy: new NoopScrollStrategy(),
    });
    let leftToTier = Number(this.allTiers[i].blpAmount) - (Number(this.lockerUserBalance) + Number(this.stakingUserBalance) + Number(this.bonusBalance));
    if (leftToTier < 0) {
      leftToTier = 0;
    }
    dialogRef.componentInstance.tierId = i;
    dialogRef.componentInstance.tierProgress = this.getTierPercent(i, this.allTiers[i].blpAmount);
    dialogRef.componentInstance.tierAmount = this.allTiers[i].blpAmount;
    dialogRef.componentInstance.currentAmount = String(Number(this.lockerUserBalance) + Number(this.stakingUserBalance) + Number(this.bonusBalance));
    dialogRef.componentInstance.leftAmount = String(leftToTier);
  }

  public get needToWaitTime(): number {
    const needTiWait = this.lastParticipationTime + this.lockupDuration - this.now;
    if (needTiWait > 0) {
      return needTiWait;
    } else {
      return 0;
    }
  }

  public getTierPercent(i: number, current: any): number {
    if (current && i <= this.nextTierIndex) {
      return (Number(this.lockerUserBalance) + Number(this.stakingUserBalance) + Number(this.bonusBalance)) * 100 / Number(current);
    }
    return 0;
  }

  public toNumber(input: bigint): number {
    return Number(input);
  }
}
