<div class="modal-header">
  <h5 class="modal-title">
    <img [src]="tierId | imgTierPipe" alt="" />
    {{getTranslatedTier(tierId)}}
  </h5>
  <button class="btn-close" [mat-dialog-close]></button>
</div>

<div class="modal-body ">
<!--  <div class="mb-4 small-text checked-list" [innerHTML]="tierId | descriptionTierPipe"></div>-->

  <div class="mb-4 progress-group">
    <div class="text-muted" *ngIf="leftAmount != '0'">
      {{leftAmount | shiftDecimals:-18| number: '1.0-2'}}
      {{'blpLeftToLockinFor' | translate}} {{getTranslatedTier(tierId)}}
    </div>
    <div class="mt-2 progress progress-xs">
      <div class="progress-bar"
           role="progressbar"
           [style.width.%]="tierProgress"></div>
    </div>
    <div class="progress-legend">
      <div class="progress-legend__percent">{{currentAmount| shiftDecimals:-18| number: '1.0-2'}} PACA</div>
      <div class="progress-legend__value">{{ tierAmount | shiftDecimals:-18| number: '1.0-2'}} PACA</div>
    </div>
  </div>


  <button mat-raised-button color="primary" class="w-100" [mat-dialog-close]="true">
    {{'gotIt' | translate}}
    <span class="rainbow-border"></span>
  </button>
</div>
