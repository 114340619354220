<section class="section-alert" *ngIf="1<0">
  <div class="alert alert-warning" role="alert">
    <div class="alert-icon icomoon-info"></div>
    <div class="alert-heading">
      {{ 'warningTextAdmins' | translate }}
    </div>
  </div>
</section>

<info-join-deals></info-join-deals>

<section class="dashboard-section p-40">
  <div class="row mb-40 ">
    <div class="col-lg-5 mb-4 mb-lg-0 d-flex flex-column">
      <div class="card p-4 mb-20">
        <div class="subtitle-1 mb-4 d-flex align-items-center">
          <i class="icon icomoon-wallet me-12 text-muted"></i>
          {{ 'wallet' | translate }}
        </div>
        <div class="h5 mb-4">{{ userWalletBalance| shiftDecimals:-18| number: '1.0-2' }} PACA</div>

        <button mat-raised-button color="primary" class="w-100 mb-2 white-card" (click)="lockTokenClick()">
          Lock PACA
          <span class="rainbow-border"></span>
        </button>

        <a href="https://app.uniswap.org/swap?chain=base&outputCurrency=0x3639e6f4c224ebd1bf6373c3d97917d33e0492bb"
           target="_blank"
           mat-raised-button
           color="primary"
           class="w-100">
          {{ 'buy' | translate }} PACA
          <span class="rainbow-border"></span>
        </a>
      </div>

      <div class="card p-4" [ngStyle]="{ 'min-height': !isLockedBalanceExist ? 'calc(50% - 10px)' : 'unset' }">
        <div class="subtitle-1 mb-4 d-flex align-items-center">
          <i class="icon icomoon-lock me-12 text-muted"></i>
          {{ 'lockedInTokens' | translate }}
        </div>
        <div class="h5 mb-4">{{ lockerUserBalance| shiftDecimals:-18| number: '1.0-2' }} PACA</div>

        <div class="__unlock-group mb-4 d-flex align-items-center">
          <button mat-raised-button color="primary" class="__unlock-tokens white-card" (click)="unlockTokenClick()">
            {{ 'unlockTokens' | translate }}
            <span class="rainbow-border"></span>
          </button>
          <!-- <button *ngIf="canTransfer" mat-raised-button color="primary" class="__unlock-tokens white-card"
                  (click)="transferTokenClick()">
            {{ 'transferTokens' | translate }}
            <span class="rainbow-border"></span>
          </button> -->
          <button mat-icon-button class="__unlock-info white-card" (click)="openUnlockInfoDlg()">
            <i class="icomoon-info"></i>
          </button>
        </div>
        <div *ngIf="userLockingStartTimestamp" class="__unlock-date d-lg-flex align-items-center justify-content-between text-muted">
          <div>{{ 'stakingStartDate' | translate }}</div>
          <div>{{ userLockingStartTimestamp * 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang) }}</div>
        </div>
        <div class="__unlock-date d-lg-flex align-items-center justify-content-between text-muted"
             *ngIf="0<needToWaitTime">
          <div>{{ 'timeLockupEnds' | translate }}</div>
          <div>{{ getTranslatedShowPeriod(needToWaitTime) }}</div>
        </div>

        <div class="card-header d-flex align-items-center mt-4">
          <i class="icon icomoon-chart-down me-12 text-muted"></i>
          <div class="subtitle-1">Penalties</div>

          <!-- <div class="info-tooltip icomoon-info ms-auto"
               matTooltipClass="tooltip-md"
               matTooltipPosition="above"
               matTooltip="There are penalties when you unlock, based on the date you deposited your last tokens"></div> -->
        </div>
        <div class="card-content p-0">
          <table class="table penalties-table">
            <tr *ngFor="let currentPenalty of allPenalties.slice(0, 4); index as i;">
              <th>Less than {{toNumber(currentPenalty.duration)|showPeriod}}</th>
              <td>{{toNumber(currentPenalty.penaltyBP)/100}}%</td>
            </tr>
            <!-- <tr *ngIf="allPenalties.length==5">
              <th>After {{toNumber(allPenalties[4].duration)|showPeriod}}</th>
              <td>0%</td>
            </tr> -->
          </table>
        </div>
      </div>

      <div class="card p-4 mt-4" *ngIf="isLockedBalanceExist">
        <div class="subtitle-1 mb-4 d-flex align-items-center">
          <i class="icon icomoon-wallet me-12 text-muted"></i>
          {{ 'oldBlpTokens' | translate }}
        </div>
        <div class="h5 mb-4">{{ lockerOldUserBalance| shiftDecimals:-18| number: '1.0-20' }} PACA</div>

        <button mat-stroked-button
                class="__unlock-tokens"
                (click)="lockTokenOldClick(false)">
          {{ 'unlockOldTokens' | translate }}
        </button>
      </div>
    </div>

    <div class="col-lg-7">
      <div class="card min-h-100">
        <div class="card-header">
          <div class="subtitle-1 d-flex align-items-center">
            <i class="icon icomoon-chart-pie me-12 text-muted"></i>
            {{ 'summary' | translate }}
          </div>
        </div>
        <div class="card-content">
          <div class="caption text-muted mb-1">{{ 'yourTier' | translate }}</div>
          <div class="__current-tier">
            <img [src]="userTierIndex | imgTierPipe" alt=""/>
            <span class="subtitle-1">{{ getTranslatedTier(userTierIndex) }}</span>
          </div>

          <div class="progress-group mb-1">
            <div class="progress-legend">
              <div class="progress-legend__value">
                <span *ngIf="!isMaxTier">{{ nextTierNeedValue | shiftDecimals:-18| number: '1.0-2' }}
                  PACA {{ 'forNextTier' | translate }}</span>
              </div>
            </div>
            <div class="progress progress-xs">
              <div class="progress-bar"
                   role="progressbar"
                   [style.width.%]="currentTierProgress">
              </div>
            </div>
            <div class="progress-legend">
              <div class="progress-legend__percent">{{ totalUserBlpBalance | shiftDecimals:-18 | number: '1.0-2' }}
                PACA
              </div>
              <div class="progress-legend__value">{{ nextTierValue | shiftDecimals:-18 | number: '1.0-2' }} PACA</div>
            </div>
          </div>
        </div>
        <div class="card-footer">

          <!--<div class="__graph pt-3">
            <div class="__graph__donut d-none d-lg-block">
              &lt;!&ndash; <chart *ngIf="isAuthorized && chartData" type="doughnut" [data]="chartData" [options]="chartOptions"></chart> &ndash;&gt;
              <canvas *ngIf="isAuthorized && chartData" style="height: 10vh" baseChart [data]="chartData"
                      [type]="'doughnut'" [options]="doughnutChartOptions" [legend]="false"></canvas>
              <div class="dm-donut &#45;&#45;placeholder" *ngIf="!isAuthorized || !chartData"></div>
            </div>
            <div class="__graph__legend">
              <div>
                <div class="legend-item">
                  <span class="legend-item__point" style="background:#131BCB"></span>
                  <span class="legend-item__title"><a
                    routerLink="staking">{{ 'staking' | translate }} {{ stakingPercent }}%</a></span>
                  <span class="legend-item__blp">{{ stakingUserBalance | shiftDecimals:-18| number: '1.0-2' }}
                    PACA</span>
                </div>
                &lt;!&ndash; <div class="legend-item">
                  <span class="legend-item__point" style="background:#AB57FF"></span>
                  <span class="legend-item__title"><a routerLink="farming">Farming 0%</a></span>
                  <span class="legend-item__blp">0 PACA</span>
                </div> &ndash;&gt;
                <div class="legend-item">
                  <span class="legend-item__point" style="background:#31C2B9"></span>
                  <span class="legend-item__title">{{ 'lockedIn' | translate }} {{ lockedBalancePercent }}%</span>
                  <span class="legend-item__blp">{{ lockedWithBonusValue| shiftDecimals:-18| number: '1.0-2' }}
                    PACA</span>
                </div>
              </div>
              <div class="legend-item">
                <span class="legend-item__title">{{ 'total' | translate }}</span>
                <span class="legend-item__blp">{{ totalUserBlpBalance| shiftDecimals:-18| number: '1.0-2' }} PACA</span>
              </div>
            </div>
          </div>-->

          <div class="__tiers-list">

            <div class="__tiers-list-item" *ngFor="let currentTier of allTiers; index as i" [class.row-two]="i >= 3">
              <div class="__tier-card"
                   [ngClass]="{'closed': !isAuthorized || i > nextTierIndex}"
                   (click)="showTierInfoDlg(i)"
                   matTooltipClass="tooltip-md"
                   matTooltipPosition="above"
                   matTooltip="{{getTranslatedTier(i)}} {{'details' | translate}}"
              >
                <div class="__tier-card-logo">
                  <img [src]="i | imgTierPipe" alt=""/>
                </div>
                <div class="__tier-card-name">{{ getTranslatedTier(i) }}</div>
                <div class="__tier-card-progress">
                  <div class="progress progress-xs">
                    <div class="progress-bar"
                         role="progressbar"
                         [style.width.%]="getTierPercent(i, currentTier.blpAmount)"></div>
                  </div>
                </div>
                <div class="__tier-card-value">
                  {{ currentTier.blpAmount| shiftDecimals:-18 }} PACA
                </div>
                <div class="__tier-card-checked" *ngIf="i <= userTierIndex">
                  <i class="icon icon-xs icomoon-checked text-primary"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-40" *ngIf="bonusBalance">
    <div class="col-lg-7 mb-4 mb-lg-0 order-lg-2">
      <div class="card p-4 min-h-100">
        <div class="subtitle-1 mb-4 d-flex align-items-center">
          <i class="icon icomoon-wallet me-12 text-muted"></i>
          {{ 'specialTierInstructions' | translate }}
        </div>
        <div class="bonus-tokens-block">
          <div class="bonus-tokens-items">
            <div class="bonus-token-item">
              {{ 'specialTokensItem1' | translate }}
            </div>
            <div class="bonus-token-item">
              {{ 'specialTokensItem2' | translate: {bonusInterval} }}
            </div>
            <div class="bonus-token-item">
              {{ 'specialTokensItem3' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-5 order-lg-1 d-flex flex-column">
      <div class="card p-4 min-h-100">
        <div class="subtitle-1 mb-4 d-flex align-items-center">
          <i class="icon icomoon-wallet me-12 text-muted"></i>
          {{ 'specialTokens' | translate }}
        </div>
        <div class="h5 mb-4">{{ bonusBalance| shiftDecimals:-18| number: '1.0-20' }} PACA</div>
      </div>
    </div>
  </div>

  <!--<div class="row">
    <div class="col-lg-12 mb-4 mb-lg-0 order-lg-2">
      <div class="card min-h-100">
        <div class="card-header">
          <div class="subtitle-1 d-flex align-items-center">
            <i class="icon icomoon-chart me-12 text-muted"></i>
            {{ 'tierProgress' | translate }}
          </div>
        </div>
        <div class="__tiers-list">
          <ng-container *ngFor="let currentTier of allTiers; index as i">
            <div class="__tiers-list-item" *ngIf="i != 5" [class.row-two]="i >= 3">
              <div class="__tier-card"
                   [ngClass]="{'closed': !isAuthorized || i > nextTierIndex}"
                   (click)="showTierInfoDlg(i)"
                   matTooltipClass="tooltip-md"
                   matTooltipPosition="above"
                   matTooltip="{{getTranslatedTier(i)}} {{'details' | translate}}"
              >
                <div class="__tier-card-logo">
                  <img [src]="i | imgTierPipe" alt=""/>
                </div>
                <div class="__tier-card-name">{{ getTranslatedTier(i) }}</div>
                <div class="__tier-card-progress">
                  <div class="progress progress-xs">
                    <div class="progress-bar"
                         role="progressbar"
                         [style.width.%]="getTierPercent(i, currentTier.blpAmount)"></div>
                  </div>
                </div>
                <div class="__tier-card-value">
                  {{ currentTier.blpAmount| shiftDecimals:-18 }} PACA
                </div>
                <div class="__tier-card-checked" *ngIf="i <= userTierIndex">
                  <i class="icon icon-xs icomoon-checked text-primary"></i>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    &lt;!&ndash;<div class="col-lg-5 order-lg-1 d-flex flex-column">
      <div class="card benefits-card mb-2">
        <div class="card-header">
          <div class="card-header-title">
            <i class="icon icomoon-award text-muted"></i>
            <div class="subtitle-1">{{'lockerBenefits' | translate}}</div>
          </div>

          <div class="card-header-title">
            <i class="icon icomoon-award text-muted"></i>
            <div class="subtitle-1">{{'stakingBenefits' | translate}}</div>
          </div>
        </div>
        <div class="card-content p-0">
          <table class="table benefits-table">
            <tr>
              <td>{{'joinOurVcIdoInoDeals' | translate}}</td>
              <td>{{'joinOurVcIdoInoDeals' | translate}}</td>
            </tr>
            <tr>
              <td>{{'noRewardsForLocking' | translate}}</td>
              <td>{{'upToAprForStaking' | translate}}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="card penalties-card flex-grow-1">
        <div class="card-header">
          <div class="card-header-title">
            <i class="icon icomoon-warning-triangle text-muted"></i>
            <div class="subtitle-1">{{'lockerPenalty' | translate}}</div>
          </div>

          <div class="card-header-title">
            <i class="icon icomoon-warning-triangle text-muted"></i>
            <div class="subtitle-1">{{'stakingPenalty' | translate}}</div>
          </div>
        </div>
        <div class="card-content p-0">
          <table class="table benefits-table">
            <tr>
              <td>{{'penaltyForEarlyUnlock' | translate}} <br/> {{'daysSinceLastJoinedDeal' | translate}}</td>
              <td>{{'penaltyForEarlyWithdraw' | translate}} <br/> {{'untilEndDateOfStakingPool' | translate}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>&ndash;&gt;
  </div>-->
</section>

