import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "imgTierPipe"
})
export class ImgTierPipe implements PipeTransform {
  transform(index: number): string {
    if (index == -1)
      return "/assets/images/tier/none.png";
    if (index == 0)
      return "/assets/images/tier/baby-paca.png";
    if (index == 1)
      return "/assets/images/tier/little-paca.png";
    if (index == 2)
      return "/assets/images/tier/chief-paca.png";
    if (index == 3)
      return "/assets/images/tier/elder-paca.png";
    if (index == 4)
      return "/assets/images/tier/alpha-paca.png";
    return "/assets/images/tier/none.png";
  }
}
