import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'apply-imo',
  templateUrl: './apply-imo.component.html',
  styleUrls: ['./apply-imo.component.scss'],
})
export class ApplyImoComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('filloutContainer', { static: true }) filloutContainer!: ElementRef;

  private scriptSrc = 'https://server.fillout.com/embed/v1/';
  formId = 'nUuzCjqA6Xus';
  constructor(

  ) {}

  ngOnInit() {
    this.loadFilloutScript();
  }

  ngAfterViewInit() {
    if (!document.querySelector('script[src="https://server.fillout.com/embed/v1/"]')) {
      const script = document.createElement('script');
      script.src = 'https://server.fillout.com/embed/v1/';
      script.async = true;
      document.body.appendChild(script);
    }
  }

  ngOnDestroy() {
    const existingScript = document.querySelector(`script[src="${this.scriptSrc}"]`);
    if (existingScript) {
      existingScript.remove();
    }

    this.filloutContainer.nativeElement.innerHTML = '';
  }

  private loadFilloutScript() {
    if (!document.querySelector(`script[src="${this.scriptSrc}"]`)) {
      const script = document.createElement('script');
      script.src = this.scriptSrc;
      script.async = true;
      document.body.appendChild(script);
    } else {
      this.reinitializeFilloutForm();
    }
  }

  private reinitializeFilloutForm() {
    this.filloutContainer.nativeElement.innerHTML = '';
    const newDiv = document.createElement('div');
    newDiv.style.width = '100%';
    newDiv.style.height = '500px';
    newDiv.setAttribute('data-fillout-id', 'nUuzCjqA6Xus');
    newDiv.setAttribute('data-fillout-embed-type', 'standard');
    newDiv.setAttribute('data-fillout-inherit-parameters', '');
    newDiv.setAttribute('data-fillout-dynamic-resize', '');
    this.filloutContainer.nativeElement.appendChild(newDiv);
  }
}
