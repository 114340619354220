<div class="modal-header">
  <i class="icon icomoon-swap"></i>
  <h5 class="modal-title">{{'transferTokens' | translate}}</h5>
  <button class="btn-close" [mat-dialog-close]></button>
</div>

<div class="modal-body">
  <mat-form-field appearance="fill" hideRequiredMarker class="mb-3">
    <mat-label>{{'from' | translate}}</mat-label>
    <input matInput value="Locker" [disabled]="true">
  </mat-form-field>

  <mat-form-field appearance="fill" hideRequiredMarker class="mb-4">
    <mat-label>{{'to' | translate}}</mat-label>
    <input matInput value="Staking Pool" [disabled]="true">
  </mat-form-field>

  <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field mb-4">
    <img src="../../assets/svg/alpaca-logo.svg" alt="">
    <mat-label>PACA</mat-label>
    <input matInput
           type="number"
           min="0"
           name="selectedAmount"
           [disabled]="waiting"
           [(ngModel)]="selectedAmount"
           required
    >
    <button mat-button color="primary" class="max-btn" (click)="setMaxBalance()">MAX</button>
    <mat-hint class="text-right">{{'available' | translate}}: {{lockedBalance| shiftDecimals:-18| number: '1.0-2'}} PACA</mat-hint>
  </mat-form-field>

  <div class="mb-4">
    <div class="subtitle-2 text-muted mb-2">{{'pleaseNoteTheDifferenceInPenalties' | translate}}:</div>
    <div class="penalty-container mb-2">
      <div class="text-muted">{{'unlockBeforeDec' | translate}}:</div>
      <div>30%</div>
    </div>
  </div>

  <div class="mb-4">
    <div class="penalty-container">
      <div class="text-muted penalty-warning"><i class="icon icomoon-warning-triangle"></i></div>
      <div>{{'youWontBeAbleMoveTokens' | translate}}</div>
    </div>
  </div>

  <div>
    <button type="button"
            mat-raised-button
            color="primary"
            class="w-100"
            [ngClass]="{'btn-loading': waiting}"
            [disabled]="waiting || !selectedAmount"
            (click)="transferClick()">
      {{'transfer' | translate}} {{selectedAmount}} PACA
    </button>
  </div>
</div>


